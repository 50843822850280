import { useFloorApartmentType } from "../types/types";
import useFetch from "./useFetch";
import React from "react";

const useFloor = (props: { lamela: number; floor: number }) => {
  const { lamela, floor } = props;
  const result =
    useFetch <
    Array<useFloorApartmentType>>(process.env.REACT_APP_STRAPI?.concat?.(`/floor?floor=${floor}&lamela=${lamela}`));

  return result;
};

export default useFloor;
