import React from "react";
import BasicScreen from "../components/BasicScreen";
import LargeText from "../components/LargeText";
import XLargeText from "../components/XLargeText";
import { useNavigate } from "react-router-dom";
import { ImageType } from "../types/types";
import useFetch from "../hooks/useFetch";
import { Slide } from "react-slideshow-image";

const Home = () => {
  const navigate = useNavigate();
  const { data } = useFetch<{ images: Array<ImageType> }>(process.env.REACT_APP_STRAPI?.concat?.(`/home`));

  const handleGoToPropery = () => {
    navigate("/apartments");
  };
  return (
    <BasicScreen
      title="Gutenbergov park"
      colorful
      imgComponent={
        data &&
        data?.images?.length > 0 && (
          <Slide easing="ease" duration={5000} arrows={false} className={`w-full h-screen object-cover`}>
            {data.images?.map((image, index) => (
              <img
                key={`HOME_SLIDER_IMAGE${image?._id}_${index}`}
                draggable="false"
                src={process.env.REACT_APP_STRAPI?.concat?.(image.url)}
                alt={`slika-${index}`}
                className="w-full h-screen object-cover "
              />
            ))}
          </Slide>
        )
      }
    >
      <div className="absolute bottom-0 left-0 right-0  flex justify-center">
        <button
          aria-label="STANOVI"
          className="bg-green border-2 p-2 text-white  mb-20 font-bold"
          onClick={handleGoToPropery}
        >
          POGLEDAJTE STANOVE
        </button>
      </div>
    </BasicScreen>
  );
};

export default Home;
