import React from "react";

import { Link } from "react-router-dom";
import Logo from "../images/logo.webp";
import MobileNavBar, { DesktopNavBar } from "./MobileNavBar";

const Header = () => {
  return (
    <div className="absolute z-10 w-full top-0">
      <div className="bg-green h-20 flex justify-end items-center p-4 ">
        <div className="absolute h-20 w-full flex justify-center left-0 right-0 items-end ">
          <Link to="/" className="self-center">
            <img src={Logo} alt="Logo" className="h-16 w-24 object-scale-down " draggable={false} unselectable="on" />
          </Link>
          <DesktopNavBar />
        </div>
        <MobileNavBar />
      </div>
      <div className="bg-brown h-2" />
    </div>
  );
};

export default Header;
