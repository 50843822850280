import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { lamelaData } from "../pages/Floor";
import RecoilStates from "../utils/recoil";
import SmallText from "./SmallText";

const links = [
  { to: "/apartments", title: "STANOVI" },
  { to: "/parkings", title: "PARKING" },
  { to: "/project", title: "PROJEKAT" },
  { to: "/location", title: "LOKACIJA" },
  { to: "/specification", title: "SPEFICIKACIJA" },
  { to: "/gallery", title: "GALERIJA" },
  { to: "/contact", title: "KONTAKT" },
];

const LinkStyle = `text-blue hover:bg-blue hover:bg-brown w-full text-center p-2 pb-0`;
const nestedLinkStyle = `p-2 relative hoverLine`;
const nestedLinkContainerStyle = `absolute flex flex-col  w-64 justify-center  bg-brown shadow-2xl `;

export const DesktopNavBar = () => {
  const lamelaDropdown = useRecoilValue(RecoilStates.dropdownState);
  const [apartmentsHovered, setApartmentsHovered] = useState(false);
  const [lamelaHovered, setLamelaHovered] = useState<number | null>(null);

  const handleApartmentHover = useCallback(() => {
    setApartmentsHovered(true);
  }, []);
  const handleApartmentHoverOut = useCallback(() => {
    setApartmentsHovered(false);
  }, []);

  const handleLamelaHover = useCallback(
    index => {
      handleApartmentHover();
      setLamelaHovered(index);
    },
    [handleApartmentHover],
  );

  const handleLamelaHoverOut = useCallback(() => {
    handleApartmentHoverOut();
    setLamelaHovered(null);
  }, [handleApartmentHoverOut]);

  return (
    <div className="hidden md:flex  w-full flex-row justify-evenly">
      <div className={`w-full flex flex-col items-end relative`}>
        <Link
          to={links[0].to}
          className={`${LinkStyle} ${apartmentsHovered ? "bg-brown" : ""}`}
          onMouseEnter={handleApartmentHover}
          onMouseLeave={handleApartmentHoverOut}
        >
          {links[0].title}
        </Link>
        <div
          className={`${
            apartmentsHovered ? "" : "hidden"
          } ${nestedLinkContainerStyle} left-1/2 -translate-x-1/2 top-full `}
          onMouseEnter={() => handleLamelaHover(0)}
          onMouseLeave={handleLamelaHoverOut}
        >
          {lamelaDropdown?.map((a, index, src) => (
            <div
              key={`DESKTOP_APARTMENT_LINK_${a._id}`}
              className={`${nestedLinkStyle} ${index === 0 ? "mt-2" : ""} ${index === src.length - 1 ? "mb-2" : ""} ${
                !a?.available ? "opacity-50" : ""
              }`}
              onMouseEnter={() => handleLamelaHover(index)}
              onMouseLeave={handleLamelaHoverOut}
            >
              <Link to={`/floor/${a.lamela}/${a.floor}`}>
                <SmallText>{a.label}</SmallText>
              </Link>
              <div
                className={`${
                  lamelaHovered === index && lamelaData?.[index + 1]?.length > 0 ? "" : "hidden"
                } ${nestedLinkContainerStyle}  top-0 left-full `}
              >
                {lamelaData?.[index + 1]?.map((l, idx) =>
                  l.to ? (
                    <Link key={`DESKTOP_LAMELA_LINK_${l.to}`} to={l.to} className={`${nestedLinkStyle}`}>
                      <SmallText>{l.text}</SmallText>
                    </Link>
                  ) : (
                    <SmallText>{l.text}</SmallText>
                  ),
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {links.slice(1)?.map((link, index) => (
        <Link
          key={`DESKTOP_MENU_${index}`}
          to={link.to}
          className={`${LinkStyle} ${window.location.pathname === link.to && link.to !== "/" ? "text-white" : ""}`}
        >
          {link.title}
        </Link>
      ))}
    </div>
  );
};

const MobileNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const genericHamburgerLine = `h-1 w-10 my-1 bg-white transition ease transform duration-300`;

  return (
    <>
      <button
        aria-label="Otvori meni"
        className="md:hidden flex flex-col h-12 w-12  justify-center items-center group"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="absolute h-12 w-12" />
        <div className={`${genericHamburgerLine} ${isOpen ? "rotate-45 translate-y-3" : ""}`} />
        <div className={`${genericHamburgerLine} ${isOpen ? "opacity-0" : ""}`} />
        <div className={`${genericHamburgerLine} ${isOpen ? "-rotate-45 -translate-y-3" : ""}`} />
      </button>

      <nav
        className={`md:hidden transition transform ease duration-300 absolute top-22  min-h-[calc(100vh_-_5.5rem)] w-full  right-0 ${
          isOpen ? " translate-x-0 " : "translate-x-full "
        }`}
      >
        <div className="bg-black opacity-90 w-full min-h-[calc(100vh_-_5.5rem)]  " />
        <div className="absolute top-0 left-0 w-full h-full flex flex-col p-4 pt-8 items-center z-30 ">
          {links?.map((link, index) => (
            <Link key={`MOBILE_MENU_${index}`} to={link.to} className="mb-6">
              {link.title}
            </Link>
          ))}
        </div>
      </nav>
    </>
  );
};

export default MobileNavBar;
