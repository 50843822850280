// @ts-ignore
import loadable from "@loadable/component";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import BasicScreen from "../components/BasicScreen";
import LargeText from "../components/LargeText";
import Title from "../components/Title";
import useFloor from "../hooks/useFloor";
import { TitleRowItem } from "../types/types";
import Loader from "../components/Loader";
import React from "react";

export const lamelaData: { [k in number]: TitleRowItem[] } = {
  1: [],
  2: [
    { to: "/floor/2/1", text: "SPRAT 1", id: 1 },
    { to: "/floor/2/2", text: "SPRAT 2", id: 2 },
    { to: "/floor/2/3", text: "SPRAT 3", id: 3 },
  ],
  3: [
    { to: "/floor/3/1", text: "SPRAT 1", id: 1 },
    { to: "/floor/3/2", text: "SPRAT 2", id: 2 },
    { to: "/floor/3/3", text: "SPRAT 3", id: 3 },
  ],
  4: [
    { to: "/floor/4/0", text: "PRIZEMLJE", id: 0 },
    { to: "/floor/4/1", text: "SPRAT 1", id: 1 },
    { to: "/floor/4/2", text: "SPRAT 2", id: 2 },
    { to: "/floor/4/3", text: "SPRAT 3", id: 3 },
  ],
};

const AsyncLamela2Horizontal = loadable(
  () => import(/* webpackChunkName:'Apartment' */ "../images/Lamela2Horizontal"),
  {
    fallback: Loader,
  },
);
const AsyncLamela4GroundHorizontal = loadable(
  () => import(/* webpackChunkName:'Apartment' */ "../images/Lamela4GroundHorizontal"),
  {
    fallback: Loader,
  },
);

const AsyncLamela4GroundVertical = loadable(
  () => import(/* webpackChunkName:'Apartment' */ "../images/Lamela4GroundVertical"),
  {
    fallback: Loader,
  },
);

const AsyncLamela4Horizontal = loadable(
  () => import(/* webpackChunkName:'Apartment' */ "../images/Lamela4Horizontal"),
  {
    fallback: Loader,
  },
);

const AsyncLamela2Vertical = loadable(() => import(/* webpackChunkName:'Apartment' */ "../images/Lamela2Vertical"), {
  fallback: Loader,
});

const AsyncLamela4Vertical = loadable(() => import(/* webpackChunkName:'Apartment' */ "../images/Lamela4Vertical"), {
  fallback: Loader,
});

const AsyncLamela3Vertical = loadable(() => import(/* webpackChunkName:'Apartment' */ "../images/Lamela3Vertical"), {
  fallback: Loader,
});
const AsyncFloorBase = loadable(() => import(/* webpackChunkName:'Apartment' */ "../images/FloorBase"), {
  fallback: Loader,
});

const ComingSoonText = () => {
  return (
    <div className="flex justify-center items-center">
      <LargeText>Uskoro</LargeText>
    </div>
  );
};

const Floor = () => {
  let params = useParams();
  const lamelaId = useMemo(() => parseInt(params?.lamelaId ?? "1", 10), [params?.lamelaId]);
  const floorId = useMemo(() => parseInt(params?.floorId ?? "1", 10), [params?.floorId]);
  const { data } = useFloor({ floor: floorId, lamela: lamelaId });

  const HorizontalElement = useMemo(() => {
    if (lamelaId === 2) {
      return AsyncLamela2Horizontal;
    }
    if (lamelaId === 3) {
      return AsyncFloorBase;
    }
    if (lamelaId === 4 && floorId === 0) {
      return AsyncLamela4GroundHorizontal;
    }
    if (lamelaId === 4) {
      return AsyncLamela4Horizontal;
    }
    return ComingSoonText;
  }, [lamelaId, floorId]);

  const VerticalElement = useMemo(() => {
    if (lamelaId === 2) {
      return AsyncLamela2Vertical;
    }
    if (lamelaId === 3) {
      return AsyncLamela3Vertical;
    }
    if (lamelaId === 4 && floorId === 0) {
      return AsyncLamela4GroundVertical;
    }
    if (lamelaId === 4) {
      return AsyncLamela4Vertical;
    }
    return ComingSoonText;
  }, [lamelaId, floorId]);

  return (
    <BasicScreen title="Lamela">
      <div className="flex flex-col items-center p-4 max-w-full ">
        <Title
          firstRow={[
            { text: "STANOVI", to: "/apartments" },
            { text: `LAMELA ${lamelaId}`, to: `/lamela/${lamelaId}` },
          ]}
          secondRow={lamelaData[lamelaId]?.map(item => ({ ...item, selected: item.id === floorId }))}
        />

        <div className="md:hidden h-full w-full p-4">
          <VerticalElement
            className="w-full h-[calc(100vh_-_16rem)]"
            floor={String(floorId)}
            lamela={String(lamelaId)}
            apartments={data?.map(a => ({ apartment: String(a?.apartment), Status: a?.Status })) ?? []}
          />
        </div>

        <div className="hidden md:block h-full w-full p-4">
          <HorizontalElement
            className="w-full h-[calc(100vh_-_16rem)]"
            floor={String(floorId)}
            lamela={String(lamelaId)}
            apartments={data?.map(a => ({ apartment: String(a?.apartment), Status: a?.Status })) ?? []}
          />
        </div>
      </div>
    </BasicScreen>
  );
};

export default Floor;
