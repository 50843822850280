import { atom } from "recoil";
import { LamelaDropdownType } from "../types/types";

const dropdownState = atom<Array<LamelaDropdownType>>({
  key: "dropdownState", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

const RecoilStates = {
  dropdownState,
};
export default RecoilStates;
