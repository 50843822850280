import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { TitleRowItem } from "../types/types";
import LargeText from "./LargeText";

const Title = (props: { title?: string; firstRow?: TitleRowItem[]; secondRow?: TitleRowItem[]; class?: string }) => {
  const hasFirstLine = props?.title || (props?.firstRow?.length ?? 0) > 0;
  return (
    <div className={`px-8 py-2 flex flex-col relative ${props?.class}`}>
      <div className="greenBG" />

      {hasFirstLine && (
        <LargeText class="md:text-2xl">
          {props?.title ||
            props?.firstRow?.map((item, index, src) =>
              item?.to ? (
                <Fragment key={`TITLE_FIRST_${item.id}_${item.to}_${index}`}>
                  <Link to={item?.to}>{item.text}</Link>
                  {index < src.length - 1 ? <>&nbsp;&gt;&nbsp;</> : ""}
                </Fragment>
              ) : (
                item.text
              ),
            )}
        </LargeText>
      )}
      {!props?.title && (props?.secondRow?.length ?? 0) > 0 && (
        <div className={`flex row flex-wrap justify-center items-center ${hasFirstLine ? "mt-2" : ""}`}>
          {props?.secondRow?.map(
            (item, index) =>
              item?.to && (
                <LargeText
                  key={`TITLE_SECOND_${item?.id}_${index}_${item?.to}`}
                  class={`md:text-2xl mx-4 ${item?.selected ? "font-bold bg-brown " : ""}`}
                >
                  <Link className={`whitespace-pre`} to={item?.to}>
                    {item.text}
                  </Link>
                </LargeText>
              ),
          )}
        </div>
      )}
    </div>
  );
};

export default Title;
