import { hot } from "react-hot-loader/root";
import React from "react";
import ReactDOM from "react-dom";
// @ts-ignore
import App from "./App";
import "./styles/index.scss";

const render = (Component: any) =>
  ReactDOM.render(
    <React.StrictMode>
      <Component />
    </React.StrictMode>,
    document.getElementById("root"),
  );

render(hot(App));
