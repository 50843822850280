import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import BackgroundImage from "../images/pozadina.webp";

const BasicScreen: React.FC<{ title?: string; colorful?: boolean; imgComponent?: ReactNode }> = props => {
  return (
    <div className="basic-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gutenbergov Park</title>
        <link href="https://api.mapbox.com/mapbox-gl-js/v2.7.0/mapbox-gl.css" rel="stylesheet" />
      </Helmet>
      <Header />
      <div className={`h-screen w-full -z-50 absolute top-0`}>
        {props?.imgComponent ?? (
          <img
            src={BackgroundImage}
            alt="Hero"
            className={`w-full h-full object-cover ${props?.colorful ? "" : "opacity-10"}`}
            draggable={false}
            unselectable="on"
          />
        )}
      </div>
      <div className="mt-22">{props.children}</div>
    </div>
  );
};

export default BasicScreen;
